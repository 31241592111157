import React, { useEffect, useState } from 'react'
import { Product_product_listProductApplications_productApplications } from '../hooks/__generated__/Product'
import { TruncatedId } from '../../truncated-id'
import { FormattedDateTime } from '../../formatted-date-time'
import { Check } from '@material-ui/icons'
import { ProductApplicationSortBy, SortOrder } from '../types'
import { CsvExport } from '../../../ui/CsvExport'
import { SuperGrid } from '../../../ui/SuperGrid'
import { useListProductApplications, QUERY as PLUS_PRODUCT_QUERY } from '../hooks/useListProductApplications'
import moment from 'moment'

const PRODUCT_ID = 'ba25bb32-7a28-4a69-a34b-87a3fdeaadd6' // The Vestaboard Plus Product ID

interface FlatPlusApplication {
  subscriptionId: string
  boardId?: string
  personId?: string
  email?: string
  purchaseDate: number
  activationDate: number
  nextBillingDate: number
  pricingType: string
  activationCode: string
  optOutOfAutoRenewal: boolean
  optOutReason?: string
  optOutDate?: number
  shopifyOrderId?: string
  shopifyOrderName?: string
}

interface FlatPlusApplicationCsv {
  subscriptionId: string
  boardId?: string
  personId?: string
  email?: string
  purchaseDate: string
  activationDate: string
  nextBillingDate: string
  pricingType: string
  activationCode: string
  optOutOfAutoRenewal: boolean
  optOutReason?: string
  optOutDate?: number
  shopifyOrderId?: string
  shopifyOrderName?: string
}

const columns = [
  {
    title: 'Subscription ID',
    key: ProductApplicationSortBy.id,
    render: (plusApplication: FlatPlusApplication) => (
      <TruncatedId value={plusApplication.subscriptionId} path={`/plus/${plusApplication.subscriptionId}`} />
    ),
    width: 150
  },
  {
    title: 'Board ID',
    key: ProductApplicationSortBy.boardId,
    render: (plusApplication: FlatPlusApplication) => (
      <TruncatedId value={plusApplication?.boardId || ''} path={`/boards/${plusApplication?.boardId}`} />
    ),
    width: 150
  },
  {
    title: 'Purchase Date',
    key: ProductApplicationSortBy.created,
    render: (plusApplication: FlatPlusApplication) => (
      <FormattedDateTime format='MM/DD/YYYY' value={plusApplication.purchaseDate} />
    ),
    width: 100
  },
  {
    title: 'Activation Date',
    key: ProductApplicationSortBy.effectiveOnOrAfter,
    render: (plusApplication: FlatPlusApplication) => (
      <FormattedDateTime format='MM/DD/YYYY' value={plusApplication.activationDate} />
    ),
    width: 140
  },
  {
    title: 'Next Billing Date',
    key: ProductApplicationSortBy.expiresOnOrAfter,
    render: (plusApplication: FlatPlusApplication) => (
      <FormattedDateTime format='MM/DD/YYYY' value={plusApplication.nextBillingDate} />
    ),
    width: 180
  },
  {
    title: 'Pricing Type',
    key: ProductApplicationSortBy.pricingType,
    render: (plusApplication: FlatPlusApplication) => plusApplication.pricingType,
    width: 120
  },
  {
    title: 'Person ID',
    key: ProductApplicationSortBy.personId,
    render: (plusApplication: FlatPlusApplication) =>
      plusApplication?.personId ? (
        <TruncatedId value={plusApplication?.personId || ''} path={`/person/${plusApplication?.personId}`} />
      ) : null,
    width: 140
  },
  {
    title: 'Person Email',
    key: ProductApplicationSortBy.email,
    render: (plusApplication: FlatPlusApplication) => plusApplication.email || '',
    width: 200
  },
  {
    title: 'Activation Code',
    key: ProductApplicationSortBy.activationCode,
    render: (plusApplication: FlatPlusApplication) => plusApplication?.activationCode,
    width: 140
  },
  {
    title: 'Opted Out of Renewal',
    key: ProductApplicationSortBy.optOutOfRenewal,
    render: (plusApplication: FlatPlusApplication) => (plusApplication?.optOutOfAutoRenewal ? <Check /> : null),
    width: 170
  },
  {
    title: 'Opted Out Date',
    key: ProductApplicationSortBy.optOutDate,
    render: (plusApplication: FlatPlusApplication) => (
      <FormattedDateTime format='MM/DD/YYYY' value={plusApplication.optOutDate} />
    ),
    width: 120
  },
  {
    title: 'Opted Reason',
    key: ProductApplicationSortBy.optOutReason,
    render: (plusApplication: FlatPlusApplication) => plusApplication.optOutReason,
    width: 200
  },
  {
    title: 'Shopify Order ID',
    key: ProductApplicationSortBy.shopifyOrderId,
    render: (plusApplication: FlatPlusApplication) => (
      <a
        style={{
          color: '#3f51b5',
          fontSize: '1rem',
          textDecoration: 'none'
        }}
        target='_blank'
        rel='noopener noreferrer'
        href={`https://vestaboard-plus.myshopify.com/admin/orders/${plusApplication?.shopifyOrderId}`}>
        {plusApplication?.shopifyOrderId || ''}
      </a>
    ),
    width: 120
  },
  {
    title: 'Shopify Order Name',
    key: ProductApplicationSortBy.shopifyOrderName,
    render: (plusApplication: FlatPlusApplication) => plusApplication?.shopifyOrderName,
    width: 170
  }
]

export const PlusList = () => {
  const perPage = 50
  const [sort, setSort] = useState<{
    by: ProductApplicationSortBy
    order: SortOrder
  }>({
    by: ProductApplicationSortBy.effectiveOnOrAfter,
    order: SortOrder.desc
  })
  const [searchValue, setSearchValue] = useState<string | null>(null)
  const [page, setPage] = useState(0)
  const variables = {
    id: PRODUCT_ID,
    page,
    perPage,
    terms: (searchValue || '').trim(),
    sortBy: sort.by,
    sortOrder: sort.order
  }
  const { data, loading, error } = useListProductApplications(variables)

  useEffect(() => {}, [page, perPage, searchValue, sort])

  const plusApplications = data?.product.listProductApplications.productApplications || []
  const pagination = data?.product.listProductApplications?.pagination

  const createFlatPurchase = (app: Product_product_listProductApplications_productApplications) =>
    ({
      subscriptionId: app.id,
      boardId: app.board?.id,
      personId: app.purchaseLineItem?.purchase?.account?.person?.id,
      email: app.purchaseLineItem?.purchase?.emailAddress,
      purchaseDate: moment(app.created).format('YYYY-MM-DD hh:mm'),
      activationDate: moment(app.started).format('YYYY-MM-DD hh:mm'),
      nextBillingDate: moment(app.ends).format('YYYY-MM-DD hh:mm'),
      pricingType: app.pricing?.title || '',
      activationCode: app.activationCode?.code || '',
      optOutOfAutoRenewal: app.optOutOfAutoRenewal,
      optOutReason: app.optOutReason,
      optOutDate: app.optOutDate,
      shopifyOrderId: app.purchaseLineItem?.purchase?.purchaseSession?.shopifyOrderId,
      shopifyOrderName: app.purchaseLineItem?.purchase?.purchaseSession?.shopifyOrderName
    } as FlatPlusApplicationCsv)

  const visiblePlusApplications = (plusApplications || []).map(createFlatPurchase)

  return (
    <>
      <SuperGrid
        title='Activated Subscriptions'
        setSort={setSort}
        columns={columns}
        rows={visiblePlusApplications}
        sortBy={sort.by}
        sortOrder={sort.order}
        pagination={pagination}
        setPage={setPage}
        error={!!error}
        loading={loading}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        centerButtons={
          <CsvExport
            query={PLUS_PRODUCT_QUERY}
            variables={variables}
            exportPerPage={1000}
            getPagination={({ data }) => ({
              ...data.product.listProductApplications.pagination,
              page: data.product.listProductApplications.pagination.page + 1
            })}
            formatData={({ data }) => {
              return data.product.listProductApplications.productApplications.map(createFlatPurchase)
            }}
          />
        }
      />
    </>
  )
}
