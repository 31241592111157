import React, { useState } from 'react'
import { ApolloConsumer } from '@apollo/react-common'
import { DocumentNode } from 'graphql'
import { Button } from '@material-ui/core'
import { GetApp } from '@material-ui/icons'
import { LoadMask, useToasts } from '@vestaboard/installables'
import { csvDownload, csvFormat } from '../utils'

interface ICsvExport {
  query: DocumentNode
  variables: any
  paginationKey?: string
  exportPerPage?: number
  getPagination?: (
    data: any
  ) => {
    page: number
    perPage: number
    total: number
  }
  formatData: (data: any) => any[]
  noInputVariables?: boolean
}

const PER_PAGE = 100

export const CsvExport = (props: ICsvExport) => {
  const [loading, setLoading] = useState(false)
  const { addToast } = useToasts()

  return (
    <ApolloConsumer>
      {apollo => {
        const buildPaginationPage = async (prev: any[], page: number): Promise<any[]> => {
          const paginationKeys = {
            perPage: props.exportPerPage || PER_PAGE,
            page
          }
          try {
            const result = await apollo.query({
              fetchPolicy: 'network-only',
              query: props.query,
              variables: {
                ...props.variables,
                ...paginationKeys,
                input: {
                  ...props.variables.input,
                  ...paginationKeys
                }
              }
            })
            const pagination = props.getPagination
              ? props.getPagination(result)
              : {
                  perPage: props.exportPerPage || PER_PAGE,
                  page,
                  // fake total for tables without a total pagination
                  total: 100000
                }
            const current = props.formatData(result)
            if (!props.getPagination && current.length === 0) {
              return prev
            }
            const data = [...prev, ...current]
            if (pagination.page * pagination.perPage > pagination.total) {
              return data
            }
            return await buildPaginationPage(data, page + 1)
          } catch (e) {
            // return the spreadsheet when we can no longer request more data
            // usually an issue with index.max_result_window on elasticsearch
            console.error(e)
            console.log('Error fetching page', page, 'Likely an issue with index.max_result_window on elasticsearch')
            addToast(`Error fetching page ${page} (${props.exportPerPage || PER_PAGE} per page)`, {
              appearance: 'error'
            })
            return prev
          }
        }

        return (
          <>
            {loading ? <LoadMask /> : null}
            <Button
              endIcon={<GetApp />}
              variant='outlined'
              onClick={async () => {
                setLoading(true)
                const result = await buildPaginationPage([], 0)
                const csv = csvFormat(result, Object.keys(result[0]))
                setLoading(false)
                csvDownload(csv)
              }}>
              Export CSV
            </Button>
          </>
        )
      }}
    </ApolloConsumer>
  )
}
