import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import {
  MarketplaceListingSubscriptions,
  MarketplaceListingSubscriptionsVariables
} from './__generated__/MarketplaceListingSubscriptions'

export const MPL_SUBSCRIPTIONS_DEVICE_QUERY = gql`
  query MarketplaceListingSubscriptions($id: String!, $page: Int, $perPage: Int) {
    marketplaceListing(id: $id) {
      id
      title
      adminInstallations(page: $page, perPage: $perPage) {
        id
        created
        subscriptions {
          id
          title
          muted
          configurationUrl
          subscriptionSet {
            id
            boards {
              id
              title
              devices {
                id 
                pairingCode
                firmwareVersion
                latestHeartbeat {
                	created
                }
              }
            }
          }
        }
      }
    }
  }
`

interface IUseMarketplaceListingSubscriptions {
  page?: number
  perPage?: number
}

export const useMarketplaceListingSubscriptions = (id: string, config?: IUseMarketplaceListingSubscriptions) => {
  return useQuery<MarketplaceListingSubscriptions, MarketplaceListingSubscriptionsVariables>(MPL_SUBSCRIPTIONS_DEVICE_QUERY, {
    variables: {
      id,
      page: config?.page,
      perPage: config?.perPage
    }
  })
}

