import {
  UpdateShopifyPaymentMethod,
  UpdateShopifyPaymentMethodVariables
} from './__generated__/UpdateShopifyPaymentMethod'

import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'

const MUTATION = gql`
  mutation UpdateShopifyPaymentMethod($input: UpdateShopifyPaymentMethodMutationInput!) {
    updateShopifyPaymentMethod(input: $input) {
      success
    }
  }
`

export const useUpdateShopifyPaymentMethod = () => {
  return useMutation<UpdateShopifyPaymentMethod, UpdateShopifyPaymentMethodVariables>(MUTATION)
}
