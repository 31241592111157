import { Button, ButtonGroup, Snackbar, makeStyles } from '@material-ui/core'
import React, { useState } from 'react'
import {
  UnpairDeviceFromBoardsMutation,
  UnpairDeviceFromBoardsMutationVariables
} from './__generated__/UnpairDeviceFromBoardsMutation'

import { AlertDialog } from '../alert-dialog'
import BugReportIcon from '@material-ui/icons/BugReport'
import DescriptionIcon from '@material-ui/icons/Description'
import MuiAlert from '@material-ui/lab/Alert'
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt'
import PhonelinkEraseIcon from '@material-ui/icons/PhonelinkErase'
import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'

interface IDeviceActions {
  deviceId: string
  requestLogs?: () => void
}

interface IResponse {
  severity: 'error' | 'success'
  message: string
}

const useStyle = makeStyles({
  container: {
    marginTop: 32,
    display: 'flex',
    justifyContent: 'center'
  }
})

const UNPAIR_DEVICE_MUATION = gql`
  mutation UnpairDeviceFromBoardsMutation($pairedDeviceId: String!) {
    unpairDeviceWithBoard(input: { pairedDeviceId: $pairedDeviceId }) {
      unpaired
    }
  }
`

export const DeviceActions = (props: IDeviceActions) => {
  const [showUnpairPrompt, setShowUnpairPrompt] = useState(false)
  const [showRestartPrompt, setShowRestartPrompt] = useState(false)
  const [showLogsPrompt, setShowLogsPrompt] = useState(false)
  const [showDebugPrompt, setShowDebugPrompt] = useState(false)
  const [response, setResponse] = useState<null | IResponse>(null)
  const [unpairDevice] = useMutation<UnpairDeviceFromBoardsMutation, UnpairDeviceFromBoardsMutationVariables>(
    UNPAIR_DEVICE_MUATION
  )
  const classes = useStyle()

  const actions = [
    {
      open: showUnpairPrompt,
      description: 'Are you sure you want to unpair this device from all boards?',
      onCancel: () => {
        setShowUnpairPrompt(false)
      },
      onConfirm: async () => {
        setShowUnpairPrompt(false)

        try {
          await unpairDevice({
            variables: {
              pairedDeviceId: props.deviceId
            }
          })

          setResponse({
            severity: 'success',
            message: 'This device has been unpaired from all boards'
          })
        } catch (err) {
          setResponse({
            severity: 'error',
            message: 'There was an error unpairing this device'
          })
        }
      },
      onShow: () => {
        setShowUnpairPrompt(true)
      },
      icon: <PhonelinkEraseIcon />,
      buttonText: 'Unpair from all boards'
    },
    {
      open: showRestartPrompt,
      description: 'Are you sure you want to restart this device?',
      onCancel: () => {
        setShowRestartPrompt(false)
      },
      onConfirm: () => {
        setShowRestartPrompt(false)
        setResponse({
          severity: 'error',
          message: 'Not implemented yet'
        })
      },
      onShow: () => {
        setShowRestartPrompt(true)
      },
      icon: <OfflineBoltIcon />,
      buttonText: 'Remotely restart'
    },
    {
      open: showLogsPrompt,
      description: 'Are you sure you want to fetch the remote logs for this device?',
      onCancel: () => {
        setShowLogsPrompt(false)
      },
      onConfirm: async () => {
        setShowLogsPrompt(false)
        if (props.requestLogs) {
          await props.requestLogs()
        }
      },
      onShow: () => {
        setShowLogsPrompt(true)
      },
      icon: <DescriptionIcon />,
      buttonText: 'Fetch verbose logs'
    },
    {
      open: showDebugPrompt,
      description: 'Are you sure you want to enable debug mode for this device?',
      onCancel: () => {
        setShowDebugPrompt(false)
      },
      onConfirm: () => {
        setShowDebugPrompt(false)
        setResponse({
          severity: 'error',
          message: 'Not implemented yet'
        })
      },
      onShow: () => {
        setShowDebugPrompt(true)
      },
      icon: <BugReportIcon />,
      buttonText: 'Enable debug mode'
    }
  ]

  const closeResponse = () => setResponse(null)

  return (
    <div className={classes.container}>
      <Snackbar open={!!response} autoHideDuration={6000} onClose={closeResponse}>
        <MuiAlert onClose={closeResponse} severity={response?.severity}>
          {response?.message}
        </MuiAlert>
      </Snackbar>
      {actions.map((dialog, key) => (
        <AlertDialog
          key={key}
          open={dialog.open}
          title='Please Confirm'
          description={dialog.description}
          onCancel={dialog.onCancel}
          onConfirm={dialog.onConfirm}
        />
      ))}
      <ButtonGroup
        orientation='horizontal'
        color='default'
        aria-label='vertical outlined primary button group'
        variant='text'>
        {actions.map((action, key) => (
          <Button size='medium' startIcon={action.icon} onClick={action.onShow} key={key}>
            {action.buttonText}
          </Button>
        ))}
      </ButtonGroup>
    </div>
  )
}
