import { AppBar, Box, CircularProgress, Tab, Tabs, Typography, makeStyles } from '@material-ui/core'
import { DeviceQuery, DeviceQueryVariables } from './__generated__/DeviceQuery'
import React, { useEffect, useState } from 'react'

import Alert from '@material-ui/lab/Alert'
import { DeviceActions } from './DeviceActions'
import { DeviceBoards } from './DeviceBoards'
import { DeviceInformation } from './DeviceInformation'
import IconButton from '@material-ui/core/IconButton'
import { Link } from '../link'
import RefreshIcon from '@material-ui/icons/Refresh'
import { gql } from 'apollo-boost'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { AlertDialog } from '../alert-dialog'
import { DeviceKeypairTokenQuery, DeviceKeypairTokenQueryVariables } from './__generated__/DeviceKeypairTokenQuery'
import { Helmet } from 'react-helmet'
import {
  SendLocalApiEnablementTokenMutation,
  SendLocalApiEnablementTokenMutationVariables
} from './__generated__/SendLocalApiEnablementTokenMutation'
import { sleep } from '../../utils/sleep'
import { useToasts } from '@vestaboard/installables'

interface IDeviceDetailProps {
  deviceId: string
}

const UPDATE_YOCTO_ROLLOUT_MUTATION = gql`
  mutation UpdateDeviceYoctoRolloutDateMutation($deviceId: String!, $rolloutDate: Long!) {
    updateDeviceYoctoStatus(input: { device: $deviceId, date: $rolloutDate }) {
      id
    }
  }
`

const SEND_LOCAL_API_ENABLEMENT_TOKEN_MUTATION = gql`
  mutation SendLocalApiEnablementTokenMutation($boardId: String!) {
    sendOwnerLocalApiEnablementToken(input: { board: $boardId }) {
      success
    }
  }
`

const useSendLocalApiEnablementTokenMutation = () =>
  useMutation<SendLocalApiEnablementTokenMutation, SendLocalApiEnablementTokenMutationVariables>(
    SEND_LOCAL_API_ENABLEMENT_TOKEN_MUTATION
  )

const useUpdateYoctoStatusMutation = () => useMutation(UPDATE_YOCTO_ROLLOUT_MUTATION)

const KEYPAIR_TOKEN_QUERY = gql`
  query DeviceKeypairTokenQuery($deviceId: String!) {
    device(id: $deviceId) {
      id
      keypair {
        id
        created
        associated
        token
      }
    }
  }
`

export const useKeypairTokenQuery = (deviceId: string) =>
  useQuery<DeviceKeypairTokenQuery, DeviceKeypairTokenQueryVariables>(KEYPAIR_TOKEN_QUERY, {
    variables: { deviceId }
  })

const DEVICE_LOGS_QUERY = gql`
  query GetDeviceLogs($deviceId: String!) {
    device(id: $deviceId) {
      id
      logsUrl
    }
  }
`

const REQUEST_DEVICE_LOGS_MUTATION = gql`
  mutation RequestDeviceLogs($deviceId: String!) {
    requestDeviceLogs(input: { device: $deviceId }) {
      requested
    }
  }
`

export const DEVICE_QUERY = gql`
  query DeviceQuery($deviceId: String!) {
    device(id: $deviceId) {
      id
      conditions
      created
      hardware {
        id
        boiSerialNumber
        tags
      }
      model {
        id
        title
      }
      type
      pairingCode
      yoctoStatus
      macAddresses
      firmwareVersion
      localApiEnablementToken
      boards {
        id
        title
      }
      latestHeartbeatRaw
      latestHeartbeat {
        id
        created
        ipAddress
        location
        timeago
        maskStatus
        networks {
          up
          ssid
          ipAddress
          online
          type
        }
        containers {
          id
          image
          since
          version
        }
        osVersion {
          id
          versionName
        }
        firmwareUpgradeStatus {
          status
          progress
        }
      }
      characterSet {
        id
        title
      }
      configurationNetwork {
        id
        ssid
        wpa2Key
      }
    }
  }
`

const useStyles = makeStyles({
  root: {
    width: '100%'
  },
  tabs: {
    marginTop: 14,
    background: '#333'
  }
})

export const DeviceDetail = (props: IDeviceDetailProps) => {
  const { addToast } = useToasts()
  const [refetching, setRefetching] = useState(false)
  const classes = useStyles()
  const [tab, setTab] = useState('information')
  const [updateYoctoStatusMutation] = useUpdateYoctoStatusMutation()
  const [sendLocalApiEnablementTokenEmail] = useSendLocalApiEnablementTokenMutation()
  const [localApiEmailSent, setLocalApiEmailSent] = React.useState(false)
  const [pollInterval, setPollInterval] = React.useState(60000)
  const [logsUrl, setLogsUrl] = React.useState('')
  const [downloadLogs, setDownloadLogs] = React.useState(false)

  const { data, loading, error, refetch } = useQuery<DeviceQuery, DeviceQueryVariables>(DEVICE_QUERY, {
    fetchPolicy: 'no-cache',
    variables: {
      deviceId: props.deviceId
    },
    pollInterval: pollInterval
  })
  const heartbeatFirmwareVersion = data?.device?.latestHeartbeat?.osVersion?.versionName

  const { data: logsData } = useQuery(DEVICE_LOGS_QUERY, {
    skip: !downloadLogs,
    variables: {
      deviceId: props.deviceId
    }
  })
  const [requestLogs] = useMutation(REQUEST_DEVICE_LOGS_MUTATION)
  const [mustConfirmYoctoRollout, setMustConfirmYoctoRollout] = React.useState(false)
  const [, setUpdatingYoctoStatus] = React.useState(false)
  const [mustConfirmEnablementTokenEmail, setMustConfirmEnablementTokenEmail] = React.useState(false)
  useEffect(() => {
    if (downloadLogs && logsData?.device?.logsUrl && logsData?.device?.logsUrl !== logsUrl) {
      setLogsUrl(logsData.device.logsUrl)
      addToast('Logs Downloaded', { appearance: 'success' })
      window.open(logsData.device.logsUrl, '_blank')
      setDownloadLogs(false)
    }
  }, [logsData, downloadLogs, addToast, logsUrl])

  if (error) {
    return <Alert severity='error'>There was an error loading the information for this device.</Alert>
  }

  if (!data) {
    return <CircularProgress />
  }

  const updateYoctoStatus = async (datetime: number) => {
    setMustConfirmYoctoRollout(false)
    setUpdatingYoctoStatus(true)
    await updateYoctoStatusMutation({
      variables: {
        deviceId: data.device.id,
        rolloutDate: datetime
      }
    })
    await refetch()
    setUpdatingYoctoStatus(false)
  }

  const triggerLocalApiEmail = async () => {
    await sendLocalApiEnablementTokenEmail({ variables: { boardId: data.device.boards[0].id } })
    setMustConfirmEnablementTokenEmail(false)
    setLocalApiEmailSent(true)
  }

  return (
    <Box>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Device - {data?.device?.id ?? ''} - Detail Page - Vestaboard Superadmin</title>
      </Helmet>
      <AlertDialog
        open={mustConfirmYoctoRollout}
        title={'Confirm Yocto rollout to this device'}
        description={
          'This device will become immediately eligible for Yocto and will begin updating if online and if the device has PVT-5 or later.'
        }
        onCancel={() => setMustConfirmYoctoRollout(false)}
        onConfirm={() => updateYoctoStatus(Date.now())}
      />
      <AlertDialog
        open={mustConfirmEnablementTokenEmail}
        title={'Confirm Local API Enablement Token email'}
        description={
          'The owner of the associated board will receive an email containing the Local API enablement token.'
        }
        onCancel={() => setMustConfirmEnablementTokenEmail(false)}
        onConfirm={() => triggerLocalApiEmail()}
      />
      <Typography variant='button' display='block' gutterBottom>
        <Link to='/boards'>Device</Link>
      </Typography>
      <Typography variant='h4'>
        {props.deviceId}{' '}
        {refetching || loading ? (
          <CircularProgress color='secondary' />
        ) : (
          <IconButton
            aria-label='refresh'
            onClick={() => {
              setRefetching(true)

              refetch()
                .then(() => {
                  // Add a second of delay because it loads so fast it feels unsatisfying
                  setTimeout(() => {
                    setRefetching(false)
                  }, 1000)
                })
                .catch(() => {
                  setRefetching(false)
                })
            }}>
            <RefreshIcon fontSize='large' htmlColor='#999' />
          </IconButton>
        )}
      </Typography>
      <Box>
        <AppBar className={classes.tabs} position='static'>
          <Tabs value={tab} onChange={(_, key) => setTab(key)}>
            <Tab label='Information' value={'information'} />
          </Tabs>
        </AppBar>
        {tab === 'boards' ? (
          <DeviceBoards deviceId={props.deviceId} />
        ) : (
          <DeviceInformation
            localApiEmailTriggered={localApiEmailSent}
            makeEligibleForYocto={() => setMustConfirmYoctoRollout(true)}
            sendLocalApiToken={() => setMustConfirmEnablementTokenEmail(true)}
            deviceId={props.deviceId}
            data={data}
            setPollInterval={setPollInterval}
            refetch={refetch}
          />
        )}
        <DeviceActions
          deviceId={props.deviceId}
          requestLogs={async () => {
            if (heartbeatFirmwareVersion) {
              addToast('Fetching logs...', { appearance: 'success' })
              await requestLogs({
                variables: { deviceId: props.deviceId },
                awaitRefetchQueries: true,
                refetchQueries: [
                  {
                    query: DEVICE_LOGS_QUERY,
                    variables: { deviceId: props.deviceId }
                  }
                ]
              })
              await sleep(10000)
              setDownloadLogs(true)
            } else {
              addToast('Must be on latest firmware.', { appearance: 'error' })
            }
          }}
        />
      </Box>
    </Box>
  )
}
