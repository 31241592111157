import {
  Button,
  ButtonBase,
  Card,
  CircularProgress,
  Link,
  Switch,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  makeStyles
} from '@material-ui/core'
import { Confirm, FlexHorizontal, Modal, Spacer, useToasts } from '@vestaboard/installables'
import React, { useCallback } from 'react'

import { Alert } from '@material-ui/lab'
import { Check } from '@material-ui/icons'
import { FormattedDateTime } from '../../formatted-date-time'
import { Link as MuiLink } from 'react-router-dom'
import { RenewalDateEditor } from '../components/RenewalDateEditor'
import { SUBSCRIPTION_EDITORS } from '../../../constants'
import { SelectPricing } from '../components/SelectPricing'
import { TruncatedId } from '../../truncated-id'
import moment from 'moment'
import { useGetProductApplication } from '../hooks/useGetProductApplication'
import { useGetShopifyOrderCustomer } from '../hooks/useGetShopifyOrderCustomer'
import { useInvalidateActivationCode } from '../hooks/useInvalidateActivationCode'
import { useOptIntRenewal } from '../hooks/useOptIntoRenewal'
import { useOptOutOfRenewal } from '../hooks/useOptOutOfRenewal'
import { useParams } from 'react-router'
import { useRenewProductApplication } from '../hooks/useRenewProductApplication'
import { useUnInvalidateActivationCode } from '../hooks/useUnInvalidateActivationCode'
import { useUpdateProductApplication } from '../hooks/useUpdateProductApplication'
import { useUpdateShopifyPaymentMethod } from '../hooks/useUpdateShopifyPaymentMethod'

const DATE_FORMAT = 'MM/DD/YYYY'

const useStyles = makeStyles({
  title: {
    fontWeight: 'bold',
    background: '#EEE',
    width: 200,
    color: '#333'
  },
  link: {
    color: '#3f51b5',
    fontSize: '1rem',
    textDecoration: 'none'
  }
})

export const PlusView = () => {
  const classes = useStyles()
  const { addToast } = useToasts()
  const params = useParams<{ id: string }>()
  const [updateProductApplication] = useUpdateProductApplication()
  const { data, loading, error } = useGetProductApplication(params.id)
  const [optIntoRenewalMutation] = useOptIntRenewal()
  const [optOutOfRenewalMutation, { error: optOutError }] = useOptOutOfRenewal()
  const [invalidateActivationCode] = useInvalidateActivationCode()
  const [unInvalidateActivationCode] = useUnInvalidateActivationCode()
  const [confirmTriggerRenewal, setConfirmTriggerRenewal] = React.useState(false)
  const [renewProductApplication] = useRenewProductApplication()
  const [isEditingPricing, setIsEditingPricing] = React.useState(false)
  const [updateShopifyPaymentMethod] = useUpdateShopifyPaymentMethod()
  const { data: userData } = useGetShopifyOrderCustomer(
    data?.productApplication?.purchaseLineItem?.purchase?.purchaseSession?.shopifyOrderId || null
  )
  const [isConfirmingPaymentMethodUpdate, setIsConfirmingPaymentMethodUpdate] = React.useState(false)

  const shopifyCustomer =
    userData?.shopifyCustomer?.__typename === 'ShopifyCustomerV2' ? userData?.shopifyCustomer : null

  const toggleActivationCodeInvalid = useCallback(async () => {
    if (!data?.productApplication?.activationCode?.id) {
      return
    }

    try {
      if (data?.productApplication?.activationCode?.isInvalidated) {
        await unInvalidateActivationCode({
          variables: {
            id: data.productApplication.activationCode.id
          }
        })
      } else {
        await invalidateActivationCode({
          variables: {
            id: data.productApplication.activationCode.id
          }
        })
      }
    } catch (err) {}
  }, [data, invalidateActivationCode, unInvalidateActivationCode])

  const toggleRenewalOptIn = useCallback(async () => {
    if (!data?.productApplication) {
      return
    }

    try {
      if (data?.productApplication?.optOutOfAutoRenewal) {
        await optIntoRenewalMutation({
          variables: {
            input: {
              productApplicationId: data.productApplication.id
            }
          }
        })
      } else {
        await optOutOfRenewalMutation({
          variables: {
            input: {
              productApplicationId: data.productApplication.id,
              reason: 'Deactivated from SuperAdmin'
            }
          }
        })
      }
    } catch (err) {}
  }, [data, optIntoRenewalMutation, optOutOfRenewalMutation])

  const showUpdatePricingModal = useCallback(() => {
    const pricingId = data?.productApplication?.pricing?.id

    if (!pricingId) {
      return
    }

    setIsEditingPricing(true)
  }, [data])

  const plusApplication = data?.productApplication
  const isSubscriptionEditor = SUBSCRIPTION_EDITORS.includes(data?.viewer?.account?.emailAddress || 'NOT_EDITOR')

  return (
    <>
      {isConfirmingPaymentMethodUpdate ? (
        <Confirm
          open
          title='Are you sure you want to trigger a payment method update email?'
          handleAccept={async () => {
            setIsConfirmingPaymentMethodUpdate(false)
            try {
              const result = await updateShopifyPaymentMethod({
                variables: {
                  input: {
                    productApplicationId: data?.productApplication?.id || ''
                  }
                }
              })

              if (!result?.data?.updateShopifyPaymentMethod) {
                throw new Error('Error triggering email')
              }

              addToast('Email triggered', { appearance: 'success' })
            } catch (err) {
              addToast('Error triggering email', { appearance: 'error' })
            }
          }}
          handleClose={() => {
            setIsConfirmingPaymentMethodUpdate(false)
          }}
        />
      ) : null}
      {isEditingPricing ? (
        <Modal visible={isEditingPricing} onClose={() => setIsEditingPricing(false)}>
          <SelectPricing
            value={plusApplication?.pricing?.id || ''}
            onValueChange={async (productPricingId: string) => {
              setIsEditingPricing(false)
              await updateProductApplication({
                variables: {
                  input: {
                    productApplicationId: plusApplication?.id || '',
                    productPricingId
                  }
                }
              }).catch(() => {
                addToast('Error updating pricing', { appearance: 'error' })
              })
            }}
          />
        </Modal>
      ) : null}
      {confirmTriggerRenewal ? (
        <Confirm
          open
          title='Are you sure you want to trigger a renewal? This will charge the card on file and extend the subscription through the next billing period.'
          handleAccept={async () => {
            setConfirmTriggerRenewal(false)
            try {
              await renewProductApplication({
                variables: {
                  input: {
                    productApplicationId: plusApplication?.id || ''
                  }
                }
              })
              addToast('Renewal triggered', { appearance: 'success' })
            } catch (err) {
              addToast('Error triggering renewal', { appearance: 'error' })
            }
          }}
          handleClose={() => {
            setConfirmTriggerRenewal(false)
          }}
        />
      ) : null}
      {error ? (
        <Alert severity='error'>Plus subscription not found.</Alert>
      ) : loading ? (
        <CircularProgress />
      ) : (
        <>
          <Typography variant='button' display='block' gutterBottom>
            <Link to='/plus' component={MuiLink}>
              Plus Subscriptions
            </Link>
          </Typography>
          <FlexHorizontal spaceBetween>
            <Typography variant='h4'>{params.id}</Typography>
          </FlexHorizontal>
          <Spacer size='large' />
          <Typography variant='h5'>Subscription Information</Typography>
          <Spacer size='medium' />
          <Card>
            <TableContainer>
              <Table>
                <TableRow>
                  <TableCell className={classes.title}>Subscription ID</TableCell>
                  <TableCell>
                    <TruncatedId value={data?.productApplication?.id || ''} full />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.title}>Order ID</TableCell>
                  <TableCell>
                    <TruncatedId
                      value={data?.productApplication?.purchaseLineItem?.purchase?.id || ''}
                      path={`/orders/${data?.productApplication?.purchaseLineItem?.purchase?.id}`}
                      full
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.title}>Date Created</TableCell>
                  <TableCell>
                    <FormattedDateTime format={DATE_FORMAT} value={plusApplication?.created} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.title}>Activation Date</TableCell>
                  <TableCell>
                    <FormattedDateTime format={DATE_FORMAT} value={plusApplication?.started} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.title}>Activation Code</TableCell>
                  <TableCell>{plusApplication?.activationCode?.code || ''}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.title}>
                    {plusApplication?.optOutOfAutoRenewal ? 'Ending Date' : 'Renewal Date'}
                  </TableCell>
                  <TableCell>
                    {isSubscriptionEditor ? (
                      <RenewalDateEditor
                        key={plusApplication?.id}
                        defaultDate={moment(plusApplication?.ends).format('YYYY-MM-DD')}
                        productApplicationId={plusApplication?.id || ''}
                      />
                    ) : (
                      <FormattedDateTime format={DATE_FORMAT} value={plusApplication?.ends} />
                    )}
                  </TableCell>
                </TableRow>
                {/*
                <TableRow>
                  <TableCell className={classes.title}>Billing Dates</TableCell>
                  <TableCell>
                    {plusApplication?.billingDates?.length
                      ? plusApplication?.billingDates.map(billingDate => (
                          <FormattedDateTime format={DATE_FORMAT} value={plusApplication?.ends} key={billingDate} />
                        ))
                      : 'None'}
                  </TableCell>
                </TableRow>
                */}
                {plusApplication?.shopifyTransactionId ? (
                  <TableRow>
                    <TableCell className={classes.title}>Shopify Transaction ID</TableCell>
                    <TableCell>{plusApplication?.shopifyTransactionId}</TableCell>
                  </TableRow>
                ) : null}
                <TableRow>
                  <TableCell className={classes.title}>Mark Code as Invalid</TableCell>
                  <TableCell>
                    <Switch
                      checked={plusApplication?.activationCode?.isInvalidated}
                      onClick={toggleActivationCodeInvalid}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.title}>Opted Out of Renewal</TableCell>
                  <TableCell>
                    <Switch checked={plusApplication?.optOutOfAutoRenewal} onClick={toggleRenewalOptIn} />
                    {optOutError ? (
                      <Alert severity='error'>There was an error opting out this order from renewals</Alert>
                    ) : null}
                  </TableCell>
                </TableRow>
                {plusApplication?.optOutOfAutoRenewal ? (
                  <>
                    <TableRow>
                      <TableCell className={classes.title}>Opted Out Date</TableCell>
                      <TableCell>
                        <FormattedDateTime format={DATE_FORMAT} value={plusApplication?.optOutDate} />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.title}>Opt Out Reason</TableCell>
                      <TableCell>{plusApplication.optOutReason || ''}</TableCell>
                    </TableRow>
                  </>
                ) : null}
                <TableRow>
                  <TableCell className={classes.title}>Shopify Order</TableCell>
                  <TableCell>
                    <a
                      className={classes.link}
                      target='_blank'
                      rel='noopener noreferrer'
                      href={`https://vestaboard-plus.myshopify.com/admin/orders/${
                        plusApplication?.purchaseLineItem?.purchase?.purchaseSession?.shopifyOrderId || ''
                      }`}>
                      {plusApplication?.purchaseLineItem?.purchase?.purchaseSession?.shopifyOrderId || ''}
                    </a>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.title}>Shopify Order Name</TableCell>
                  <TableCell>
                    {plusApplication?.purchaseLineItem?.purchase?.purchaseSession?.shopifyOrderName || ''}
                  </TableCell>
                </TableRow>
                {isSubscriptionEditor ? (
                  <TableRow>
                    <TableCell className={classes.title}>Trigger Renewal</TableCell>
                    <TableCell>
                      <Button
                        variant='outlined'
                        disabled={plusApplication?.optOutOfAutoRenewal}
                        onClick={() => {
                          setConfirmTriggerRenewal(true)
                        }}>
                        Trigger Renewal
                      </Button>
                    </TableCell>
                  </TableRow>
                ) : null}
              </Table>
            </TableContainer>
          </Card>
          <Spacer size='extraLarge' />
          <Typography variant='h5'>Board Information</Typography>
          <Spacer size='medium' />
          <Card>
            <TableContainer>
              <Table>
                <TableRow>
                  <TableCell className={classes.title}>Board ID</TableCell>
                  <TableCell>
                    <TruncatedId
                      full
                      value={plusApplication?.board?.id || ''}
                      path={`/boards/${plusApplication?.board?.id}`}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.title}>Board Title</TableCell>
                  <TableCell>{plusApplication?.board?.title}</TableCell>
                </TableRow>
              </Table>
            </TableContainer>
          </Card>
          <>
            <Spacer size='extraLarge' />
            <Typography variant='h5'>Purchaser Information</Typography>
            <Spacer size='medium' />
            <Card>
              <TableContainer>
                <Table>
                  <TableRow>
                    <TableCell className={classes.title}>Account ID</TableCell>
                    <TableCell>
                      <TruncatedId value={plusApplication?.purchaseLineItem?.purchase?.account?.id || ''} full />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.title}>Email Address</TableCell>
                    <TableCell>
                      {shopifyCustomer?.email || plusApplication?.purchaseLineItem?.purchase?.emailAddress || ''}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.title}>Person ID</TableCell>
                    <TableCell>
                      <TruncatedId
                        value={plusApplication?.purchaseLineItem?.purchase?.account?.person?.id || ''}
                        path={`/person/${plusApplication?.purchaseLineItem?.purchase?.account?.person?.id}`}
                        full
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.title}>First Name</TableCell>
                    <TableCell>
                      {shopifyCustomer?.firstName ||
                        plusApplication?.purchaseLineItem?.purchase?.account?.person?.firstName ||
                        ''}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.title}>Last Name</TableCell>
                    <TableCell>
                      {shopifyCustomer?.lastName ||
                        plusApplication?.purchaseLineItem?.purchase?.account?.person?.lastName ||
                        ''}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.title}>Shopify Customer</TableCell>
                    <TableCell>
                      <a
                        className={classes.link}
                        target='_blank'
                        rel='noopener noreferrer'
                        href={`https://vestaboard-plus.myshopify.com/admin/customers/${
                          plusApplication?.purchaseLineItem?.purchase?.purchaseSession?.shopifyUserId || ''
                        }`}>
                        {plusApplication?.purchaseLineItem?.purchase?.purchaseSession?.shopifyUserId || ''}
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.title}>Payment Method</TableCell>
                    <TableCell>
                      {plusApplication?.purchaseLineItem?.purchase?.purchaseSession?.paymentMethod?.company || ''}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.title}>Payment Card</TableCell>
                    <TableCell>
                      {plusApplication?.purchaseLineItem?.purchase?.purchaseSession?.paymentMethod?.number || ''}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.title}>Trigger Update Email</TableCell>
                    <TableCell>
                      <Button
                        variant='outlined'
                        onClick={() => setIsConfirmingPaymentMethodUpdate(true)}
                        disabled={!data?.productApplication?.purchaseLineItem}>
                        Trigger Payment Method Update Email
                      </Button>
                    </TableCell>
                  </TableRow>
                </Table>
              </TableContainer>
            </Card>
          </>
          <Spacer size='extraLarge' />
          <Typography variant='h5'>Pricing Information</Typography>
          <Spacer size='medium' />
          <Card>
            <TableContainer>
              <Table>
                <TableRow>
                  <TableCell className={classes.title}>Pricing ID</TableCell>
                  <TableCell>
                    {isSubscriptionEditor ? (
                      <ButtonBase onClick={showUpdatePricingModal}>
                        <TruncatedId value={plusApplication?.pricing?.id || ''} />
                      </ButtonBase>
                    ) : (
                      <TruncatedId value={plusApplication?.pricing?.id || ''} />
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.title}>Pricing Title</TableCell>
                  <TableCell>
                    {isSubscriptionEditor ? (
                      <ButtonBase onClick={showUpdatePricingModal}>{plusApplication?.pricing?.title}</ButtonBase>
                    ) : (
                      plusApplication?.pricing?.title
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.title}>Pricing Tagline</TableCell>
                  <TableCell>{plusApplication?.pricing?.tagline}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.title}>Pricing Banner</TableCell>
                  <TableCell>{plusApplication?.pricing?.banner}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.title}>Auto-Renew?</TableCell>
                  <TableCell>{!!plusApplication?.pricing?.autoRenew ? <Check /> : null}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.title}>Price</TableCell>
                  <TableCell>${((plusApplication?.pricing?.priceInCents || 0) / 100).toFixed(2)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.title}>Quantity</TableCell>
                  <TableCell>{plusApplication?.pricing?.quantity}</TableCell>
                </TableRow>
                {plusApplication?.pricing?.shopifySellingPlanId ? (
                  <TableRow>
                    <TableCell className={classes.title}>Shopify Selling Plan ID</TableCell>
                    <TableCell>{plusApplication?.pricing?.shopifySellingPlanId}</TableCell>
                  </TableRow>
                ) : null}
                {plusApplication?.pricing?.shopifyVariantId ? (
                  <TableRow>
                    <TableCell className={classes.title}>Shopify Variant ID</TableCell>
                    <TableCell>{plusApplication?.pricing?.shopifyVariantId}</TableCell>
                  </TableRow>
                ) : null}
                <TableRow>
                  <TableCell className={classes.title}>Product</TableCell>
                  <TableCell>{plusApplication?.pricing?.product.title}</TableCell>
                </TableRow>
              </Table>
            </TableContainer>
          </Card>
        </>
      )}
    </>
  )
}
