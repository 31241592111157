import { GetShopifyOrderCustomer, GetShopifyOrderCustomerVariables } from './__generated__/GetShopifyOrderCustomer'

import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'

const QUERY = gql`
  query GetShopifyOrderCustomer($orderId: String!) {
    shopifyCustomer: shopifyOrderCustomerV2(input: { orderId: $orderId }) {
      ... on ShopifyCustomerV2 {
        id
        lastName
        firstName
        email
      }
      ... on ShopifyCustomerErrorV2 {
        type
        error
      }
    }
  }
`

export const useGetShopifyOrderCustomer = (orderId: string | null) => {
  return useQuery<GetShopifyOrderCustomer, GetShopifyOrderCustomerVariables>(QUERY, {
    skip: !orderId,
    variables: {
      orderId: orderId as string
    }
  })
}
