import { Box, Button, Checkbox, CircularProgress, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { useBoardDetailQuery } from '../hooks/useBoardDetailQuery'
import { Link } from '../../link'
import { Alert } from '@material-ui/lab'
import { BoardPreview, IBoard, IBoardStyle, Spacer, createBoard } from '@vestaboard/installables'
import W3CWebSocket from 'reconnecting-websocket'
import { vbml } from '@vestaboard/vbml'
import { v4 as uuid } from 'uuid'

const WS_BASE_URL = process.env.REACT_APP_WS_BASE_URL

enum EDeviceMessageType {
  DisplayCommand = 'DeviceMessageDisplayCommand',
  Heartbeat = 'DeviceHeartbeatMessage',
  VerboseHeartBeat = 'DeviceHeartbeatMessage',
  BoardLayoutChanged = 'DeviceBoardLayoutChangedMessage',
  Refresh = 'DeviceRefreshCommand',
  Identify = 'DeviceIdentifyMessage'
}

const constructDeviceMessage = (deviceId: string, type: EDeviceMessageType, payload: {}) => {
  return JSON.stringify({
    message: {
      id: uuid(),
      type,
      deviceId,
      clientTime: new Date().getTime(),
      maskStatus: 'On',
      ...payload
    }
  })
}

const constructVerboseHeartbeatMessage = (deviceId: string) => {
  return constructDeviceMessage(deviceId, EDeviceMessageType.VerboseHeartBeat, {
    created: new Date().getTime(),
    networkInterfaces: [
      {
        name: 'wlan0',
        iface: 'wlan0',
        isUp: true,
        mtu: 100,
        inetAddresses: [''],
        interfaceAddresses: [''],
        isLoopback: false,
        isVirtual: false,
        index: 0,
        hardwareAddress: '00:00:00:00'
      }
    ],
    currentNetwork: {
      ssid: 'ssid-simulator-heartbeat',
      bssid: 'bssid-simulator-heartbeat',
      freq: 0,
      ipAddress: '127.0.0.1',
      isUp: true
    },
    diagnostics: {
      networkInterfaces: [
        {
          name: 'wlan0',
          iface: 'wlan0',
          isUp: true,
          mtu: 100,
          inetAddresses: [''],
          interfaceAddresses: [''],
          isLoopback: false,
          isVirtual: false,
          index: 0,
          hardwareAddress: '00:00:00:00'
        }
      ],
      currentNetwork: {
        ssid: 'ssid-simulator-heartbeat',
        bssid: 'bssid-simulator-heartbeat',
        freq: 0,
        ipAddress: '127.0.0.1',
        isUp: true
      }
    }
  })
}

const constructHeartbeatMessage = (deviceId: string) => {
  return constructDeviceMessage(deviceId, EDeviceMessageType.Heartbeat, {
    created: new Date().getTime()
  })
}

const constructIdentifyMessage = (deviceId: string, boardId: string) => {
  return constructDeviceMessage(deviceId, EDeviceMessageType.Identify, { boardId })
}

export const LiveBoard = () => {
  const { id: boardId } = useParams<{ id: string }>()
  const { data, error, loading } = useBoardDetailQuery(boardId)
  const [sendVerboseHeartbeats, setSendVerboseHeartbeats] = useState<boolean>(false)
  const [sendHeartbeats, setSendHeartbeats] = useState<boolean>(false)
  const [showWebsocketConnectEvents, setShowWebsocketConnectEvents] = useState<boolean>(true)
  const [showAllLogs, setShowAllLogs] = useState<boolean>(false)
  const [viewBoardStatus, setViewBoardStatus] = useState<boolean>(false)
  const [boardStatus, setBoardStatus] = useState<any>([])

  const [message, setMessage] = useState<Array<number[]>>(createBoard())
  const [messages, setMessages] = useState<
    Array<{
      messageId: string
      message: string
      time: string
      serverTime: string
      created?: string
      delay: number
      otherEvent: boolean
    }>
  >()

  useEffect(() => {
    if (boardId && viewBoardStatus) {
      const statusUrl = `${WS_BASE_URL?.replace('/ws', '/board-status')}/${boardId}`
      const clientBoardStatus = new W3CWebSocket(statusUrl, undefined, {
        maxReconnectionDelay: 200,
        minReconnectionDelay: 0,
        reconnectionDelayGrowFactor: 0,
        connectionTimeout: 200,
        maxRetries: Infinity,
        debug: true
      })

      const onConnect = () => {
        setBoardStatus((prev: any) => [`connected ${statusUrl}`, ...prev])
      }
      const handleMessage = (message: any) => {
        setBoardStatus((prev: any) => [message, ...prev])
      }
      clientBoardStatus.onopen = () => {
        onConnect()
      }
      clientBoardStatus.onmessage = (message: any) => {
        handleMessage(message.data)
      }
      return () => {
        clientBoardStatus.close()
      }
    }
  }, [boardId, viewBoardStatus])

  useEffect(() => {
    if (!data?.board?.devices?.length) {
      return
    }

    const deviceId = data.board.devices[0].id

    if ((window as any).registered) {
      return
    }

    if (boardId) {
      const client = new W3CWebSocket(`${WS_BASE_URL}/${deviceId}`, undefined, {
        maxReconnectionDelay: 200,
        minReconnectionDelay: 0,
        reconnectionDelayGrowFactor: 0,
        connectionTimeout: 200,
        maxRetries: Infinity,
        debug: true
      })

      ;(window as any).registered = true
      const showConnect = showWebsocketConnectEvents || showAllLogs

      const onConnect = () => {
        sendHeartbeat()
        sendVerboseHeartbeatMessage()
        sendIdentify()
        scheduleHeartbeat()
        scheduleVerboseHeartbeat()
        showConnect &&
          setMessages(prev => {
            return [
              {
                messageId: 'Connected Websocket',
                message: 'Websocket reconnected and message hydrating',
                time: new Date().toLocaleString() + ' millis:' + new Date().getTime(),
                serverTime: new Date().toLocaleString() + ' millis:' + new Date().getTime(),
                delay: 0,
                otherEvent: true
              },
              ...(prev || [])
            ]
          })
      }

      const handleMessage = (message: any) => {
        const json = JSON.parse(message)

        switch (json.message.type) {
          case EDeviceMessageType.DisplayCommand:
            setMessage(json.message.characters.characters)
            setMessages(prev => {
              // remove this check if you want to see duplicate hydration messages
              if (prev?.[0]?.messageId === json?.message?.messageId) {
                return prev
              }
              return [
                {
                  messageId: `${json?.message?.type} - ${json?.message?.messageId}`,
                  message: vbml.characterCodesToAscii(json.message.characters.characters),
                  time: new Date().toLocaleString() + ' millis:' + new Date().getTime(),
                  created: new Date(json.message.created).toLocaleString() + ' millis:' + json.message.created,
                  serverTime: new Date(json.message.serverTime).toLocaleString() + ' millis:' + json.message.serverTime,
                  delay: json.message.serverTime - new Date().getTime(),
                  otherEvent: false
                },
                ...(prev || [])
              ]
            })
            break
          default:
            showAllLogs &&
              setMessages(prev => {
                return [
                  {
                    messageId: json.message.type,
                    message: JSON.stringify(json?.message) + '\n',
                    time: new Date().toLocaleString() + ' millis:' + new Date().getTime(),
                    serverTime:
                      new Date(json.message.serverTime).toLocaleString() + ' millis:' + json.message.serverTime,
                    delay: json.message.serverTime - new Date().getTime(),
                    otherEvent: true
                  },
                  ...(prev || [])
                ]
              })
            break
        }
      }

      const sendVerboseHeartbeatMessage = () => {
        const message = constructVerboseHeartbeatMessage(deviceId)
        sendVerboseHeartbeats && client.send(message)
      }

      const sendHeartbeat = () => {
        const message = constructHeartbeatMessage(deviceId)
        sendHeartbeats && client.send(message)
      }

      const sendIdentify = () => {
        const message = constructIdentifyMessage(deviceId, boardId)
        client.send(message)
      }

      const scheduleHeartbeat = () => {
        setTimeout(() => {
          sendHeartbeat()
          scheduleHeartbeat()
        }, 60 * 1000)
      }

      const scheduleVerboseHeartbeat = () => {
        setTimeout(() => {
          sendVerboseHeartbeatMessage()
          scheduleHeartbeat()
        }, 120 * 1000)
      }

      client.onopen = () => {
        onConnect()
      }

      client.onmessage = (message: any) => {
        handleMessage(message.data)
      }

      return () => {
        ;(window as any).registered = false
        client.close()
      }
    }
  }, [boardId, data, sendVerboseHeartbeats, showWebsocketConnectEvents, showAllLogs, sendHeartbeats])

  return error ? (
    <Alert severity='error'>{error.message || 'Board not found'}</Alert>
  ) : !data || loading ? (
    <CircularProgress />
  ) : (
    <>
      <Typography variant='button' display='block' gutterBottom>
        <Link to={`/boards`}>Board</Link> | <Link to={`/boards/${boardId}`}>{data.board.title}</Link>
      </Typography>
      <Spacer size='extraLarge' />
      <Box
        style={{
          maxWidth: 600
        }}>
        <BoardPreview characters={message as IBoard} boardStyle={data?.board?.boardStyle as IBoardStyle} />
      </Box>
      <>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}>
          <Box>
            <Typography variant='h6'>Board Status Log</Typography>
          </Box>
          {viewBoardStatus ? (
            <Box
              style={{
                borderRadius: '50%',
                width: 10,
                height: 10,
                margin: 4,
                backgroundColor:
                  boardStatus?.[0]?.includes(`"online":"Online"`) && boardStatus?.[0]?.includes(`"isOnline":true`)
                    ? 'green'
                    : 'red'
              }}
            />
          ) : (
            <Box
              style={{
                borderRadius: '50%',
                width: 10,
                height: 10,
                margin: 4,
                backgroundColor: 'grey'
              }}
            />
          )}
        </Box>
        <Box
          style={{
            flexDirection: 'row'
          }}>
          <Button
            onClick={() => {
              setBoardStatus([])
            }}
            title='Clear Log'>
            Clear Log
          </Button>
        </Box>
        <Box
          style={{
            flexDirection: 'row'
          }}>
          <Checkbox
            color='primary'
            checked={viewBoardStatus}
            onChange={() => setViewBoardStatus(prev => !prev)}
            name={'View board status websocket'}></Checkbox>
          <Typography variant='caption'>Enable board status websocket logging</Typography>
        </Box>
        <Box style={{ width: '100%', overflowX: 'scroll', maxHeight: 100, overflowY: 'scroll', paddingBottom: 8 }}>
          {boardStatus.map((message: any) => (
            <pre
              style={{
                fontSize: 8,
                padding: 0,
                backgroundColor: message.includes('connected wss')
                  ? '#eeeeee'
                  : message.includes(`"online":"Online"`) && message.includes(`"isOnline":true`)
                  ? '#90EE90'
                  : '#ff7f7f'
              }}>
              {message + '\n'}
            </pre>
          ))}
        </Box>
        <Typography variant='h6'>WebSocket Message Log</Typography>

        <Spacer size='small' />
        <Box
          style={{
            flexDirection: 'row'
          }}>
          <Button
            onClick={() => {
              setMessages([])
            }}
            title='Clear Log'>
            Clear Log
          </Button>
        </Box>

        <Spacer size='small' />
        <Box
          style={{
            flexDirection: 'row'
          }}>
          <Checkbox
            color='primary'
            checked={sendVerboseHeartbeats}
            onChange={() => setSendVerboseHeartbeats(prev => !prev)}
            name={'Send Verbose Heatbeats from Simulator'}></Checkbox>
          <Typography variant='caption'>Send Verbose Heatbeats from Simulator</Typography>
        </Box>
        <Box
          style={{
            flexDirection: 'row'
          }}>
          <Checkbox
            color='primary'
            checked={sendHeartbeats}
            onChange={() => setSendHeartbeats(prev => !prev)}
            name={'Send Heatbeats from Simulator'}></Checkbox>
          <Typography variant='caption'>Send Heatbeats from Simulator</Typography>
        </Box>

        <Box
          style={{
            flexDirection: 'row'
          }}>
          <Checkbox
            color='primary'
            checked={showWebsocketConnectEvents || showAllLogs}
            onChange={() => setShowWebsocketConnectEvents(prev => !prev)}
            name={'Show WebSocket connect events'}></Checkbox>
          <Typography variant='caption'>Show WebSocket connect events</Typography>
        </Box>

        <Box
          style={{
            flexDirection: 'row'
          }}>
          <Checkbox
            color='primary'
            checked={showAllLogs}
            onChange={() => setShowAllLogs(prev => !prev)}
            name={'Show All WebSocket Events'}></Checkbox>
          <Typography variant='caption'>Show All WebSocket Event</Typography>
        </Box>
        {messages?.map((message, index) => (
          <div
            key={index}
            style={{
              border: '1px solid #ccc',
              maxWidth: '80vw',
              borderRadius: '4px',
              padding: '4px',
              backgroundColor: message.otherEvent ? '#90EE90' : 'white',
              overflow: 'scroll'
            }}>
            <Typography variant={'body1'}>
              {index} - {message.messageId}
            </Typography>
            <Typography
              variant={message.otherEvent ? 'caption' : 'body1'}
              style={{
                maxWidth: '80vw'
              }}>
              {message.otherEvent ? message.message + ' ' + message.time : message.message}
            </Typography>
            {!message.otherEvent && (
              <>
                <Spacer size='small' />
                <Typography variant='caption'>Received by WebSocket: {message.time}</Typography>
                <Spacer size='small' />
                <Typography variant='caption'>Message Appearance Event Created: {message.created}</Typography>
                <Spacer size='small' />
                <Typography variant='caption'>Message Server Time: {message.serverTime}</Typography>
                <Spacer size='small' />
                <Typography variant='caption'>Delay Milliseconds: {message.delay}</Typography>
                <Spacer size='small' />
              </>
            )}
          </div>
        ))}
      </>
    </>
  )
}
