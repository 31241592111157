import React, { useCallback, useEffect, useState } from 'react'
import { TruncatedId } from '../../truncated-id'
import { BoardDeviceAssociationSortBy, BoardDeviceAssociationSortOrder } from '../types'
import { ListBoardDeviceAssociations_listBoardDeviceAssociations_boardDeviceAssociations } from '../hooks/__generated__/ListBoardDeviceAssociations'
import { CsvExport } from '../../../ui/CsvExport'
import { FormattedDateTime } from '../../formatted-date-time'
import { Check, Refresh } from '@material-ui/icons'
import { SuperGrid } from '../../../ui/SuperGrid'
import moment from 'moment'
import { AdvancedSearch, SearchValueType } from '../../../ui/AdvancedSearch'
import { Box, Button, Link } from '@material-ui/core'
import { isUUID } from '../../../utils'
import { SearchDeviceId } from '../components/SearchDeviceId'
import { FlexHorizontal } from '@vestaboard/installables'
import { CsvImport } from '../components/CsvImport'
import { useBoardDeviceAssociations, QUERY as BOARD_DEVICE_ASSOCIATIONS } from '../hooks/useBoardDeviceAssociations'
import { SearchSSIDComponent } from '../components/SearchSSID'
import { useIndexBoardDeviceAssociations } from '../hooks/useIndexBoardDeviceAssociations'
import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'

const REINDEX_MUTATION = gql`
  mutation indexBoardDeviceAssociationByBoard($boardId: String!) {
    indexBoardDeviceAssociationByBoard(boardId: $boardId)
  }
`

const DATE_TIME_FORMAT = `YYYY-MM-DD hh:mm:ss`

type BoardDeviceAssociation = ListBoardDeviceAssociations_listBoardDeviceAssociations_boardDeviceAssociations

const columns = ({
  indexBoardDeviceAssociations
}: {
  indexBoardDeviceAssociations: (boardDeviceAssociationId: string) => void
}) => [
  {
    title: 'Board ID',
    key: BoardDeviceAssociationSortBy.boardId,
    width: 150,
    render: (bda: BoardDeviceAssociation) => <TruncatedId value={bda.boardId} path={`/boards/${bda.boardId}`} />
  },
  {
    title: 'Board Title',
    key: BoardDeviceAssociationSortBy.boardTitle,
    width: 150,
    render: (bda: BoardDeviceAssociation) => bda.boardTitle || 'Untitled'
  },
  {
    title: 'Device ID',
    key: BoardDeviceAssociationSortBy.deviceId,
    width: 150,
    render: (bda: BoardDeviceAssociation) => <TruncatedId value={bda.deviceId} path={`/devices/${bda.deviceId}`} />
  },
  {
    title: 'Device Created',
    width: 120,
    key: BoardDeviceAssociationSortBy.deviceCreated,
    render: (bda: BoardDeviceAssociation) => <FormattedDateTime format='MM/DD/YYYY' value={bda.deviceCreated} />
  },
  {
    title: 'Board Created',
    width: 120,
    key: BoardDeviceAssociationSortBy.boardCreated,
    render: (bda: BoardDeviceAssociation) => <FormattedDateTime format={'MM/DD/YYYY'} value={bda.boardCreated} />
  },
  {
    title: 'Pairing Date',
    width: 120,
    key: BoardDeviceAssociationSortBy.boardDeviceAssociationCreated,
    render: (bda: BoardDeviceAssociation) => (
      <FormattedDateTime format='MM/DD/YYYY' value={bda.boardDeviceAssociationCreated} />
    )
  },
  {
    title: 'Device Last Seen',
    width: 120,
    key: BoardDeviceAssociationSortBy.deviceLastSeen,
    render: (bda: BoardDeviceAssociation) => <FormattedDateTime format='MM/DD/YYYY' value={bda.deviceLastSeen} />
  },
  {
    title: 'Board Deleted',
    width: 120,
    key: BoardDeviceAssociationSortBy.boardDeleted,
    render: (bda: BoardDeviceAssociation) => (bda.boardDeleted ? <Check /> : '')
  },
  {
    title: 'Pairing Code',
    width: 120,
    key: BoardDeviceAssociationSortBy.pairingCode,
    render: (bda: BoardDeviceAssociation) => bda.pairingCode || ''
  },
  {
    title: 'SSID',
    key: BoardDeviceAssociationSortBy.ssid,
    width: 170,
    render: (bda: BoardDeviceAssociation) => bda.ssid || ''
  },
  {
    title: 'Firmware Version',
    width: 130,
    key: BoardDeviceAssociationSortBy.firmwareVersion,
    render: (bda: BoardDeviceAssociation) => bda.firmwareVersion || ''
  },
  {
    title: 'OS Version Name',
    width: 130,
    key: BoardDeviceAssociationSortBy.osVersionName,
    render: (bda: BoardDeviceAssociation) => bda.osVersionName || ''
  },
  {
    title: 'Owner ID',
    key: BoardDeviceAssociationSortBy.personId,
    width: 150,
    render: (bda: BoardDeviceAssociation) =>
      bda.personId ? <TruncatedId value={bda.personId} path={`/person/${bda.personId}`} /> : ''
  },
  {
    title: 'First Name',
    width: 150,
    key: BoardDeviceAssociationSortBy.personFirstName,
    render: (bda: BoardDeviceAssociation) => bda.personFirstName || ''
  },
  {
    title: 'Last Name',
    width: 150,
    key: BoardDeviceAssociationSortBy.personLastName,
    render: (bda: BoardDeviceAssociation) => bda.personLastName || ''
  },
  {
    title: 'Owner Email',
    width: 200,
    key: BoardDeviceAssociationSortBy.accountEmailAddress,
    render: (bda: BoardDeviceAssociation) => bda.accountEmailAddress || ''
  },
  {
    title: 'Members',
    width: 100,
    key: BoardDeviceAssociationSortBy.membersCount,
    render: (bda: BoardDeviceAssociation) => bda.memberIds.length || '0'
  },
  {
    title: 'Online',
    width: 100,
    key: BoardDeviceAssociationSortBy.online,
    render: (bda: BoardDeviceAssociation) => (bda.online ? <Check /> : '')
  },
  {
    title: 'Last Message',
    width: 120,
    key: BoardDeviceAssociationSortBy.lastMessage,
    render: (bda: BoardDeviceAssociation) =>
      bda.lastMessage ? <FormattedDateTime format='MM/DD/YYYY' value={bda.lastMessage} /> : ''
  },
  {
    title: 'Messages Sent',
    width: 150,
    key: BoardDeviceAssociationSortBy.messagesAllTime,
    render: (bda: BoardDeviceAssociation) => (bda.messagesAllTime || 0).toLocaleString()
  },
  {
    title: 'Messages (30 Days)',
    width: 150,
    key: BoardDeviceAssociationSortBy.messagesInPastThirtyDays,
    render: (bda: BoardDeviceAssociation) => (bda.messagesInPastThirtyDays || 0).toLocaleString()
  },
  {
    title: 'Messages (7 Days)',
    width: 150,
    key: BoardDeviceAssociationSortBy.messagesInPastSevenDays,
    render: (bda: BoardDeviceAssociation) => (bda.messagesInPastSevenDays || 0).toLocaleString()
  },
  {
    title: 'Messages (1 Day)',
    width: 150,
    key: BoardDeviceAssociationSortBy.messagesInPastDay,
    render: (bda: BoardDeviceAssociation) => (bda.messagesInPastDay || 0).toLocaleString()
  },
  {
    title: 'Channels',
    width: 150,
    key: BoardDeviceAssociationSortBy.channels,
    render: (bda: BoardDeviceAssociation) => (bda.channels || 0).toLocaleString()
  },
  {
    title: 'Enabled Channels',
    width: 150,
    key: BoardDeviceAssociationSortBy.enabledChannels,
    render: (bda: BoardDeviceAssociation) => (bda.enabledChannels || 0).toLocaleString()
  },
  {
    title: 'Plus Subscription ID',
    width: 150,
    key: BoardDeviceAssociationSortBy.productApplicationId,
    render: (bda: BoardDeviceAssociation) =>
      bda.productApplicationId ? (
        <TruncatedId value={bda.productApplicationId} path={`/plus/${bda.productApplicationId}`} />
      ) : (
        ''
      )
  },
  {
    title: 'Plus Subscription Created',
    width: 200,
    key: BoardDeviceAssociationSortBy.productApplicationCreated,
    render: (bda: BoardDeviceAssociation) =>
      bda.productApplicationCreated ? (
        <FormattedDateTime format={DATE_TIME_FORMAT} value={bda.productApplicationCreated} />
      ) : (
        ''
      )
  },
  {
    title: 'Subscription Frequency',
    width: 200,
    key: BoardDeviceAssociationSortBy.productPricingRecurringFrequency,
    render: (bda: BoardDeviceAssociation) => bda.productPricingRecurringFrequency || ''
  },
  {
    title: 'Yocto Status',
    width: 150,
    key: BoardDeviceAssociationSortBy.yoctoStatus,
    render: (bda: BoardDeviceAssociation) => bda.yoctoStatus || ''
  },
  {
    title: 'Device Model',
    width: 150,
    key: BoardDeviceAssociationSortBy.deviceModelTitle,
    render: (bda: BoardDeviceAssociation) => bda.deviceModelTitle || ''
  },
  {
    title: 'Hubspot Deal',
    width: 150,
    key: BoardDeviceAssociationSortBy.hubspotDealId,
    render: (bda: BoardDeviceAssociation) =>
      bda.hubspotDealId ? (
        <Link
          href={`https://app.hubspot.com/contacts/1984131/deal/${bda.hubspotDealId}`}
          target='_blank'
          rel='noopener noreferrer'>
          {bda.hubspotDealId}
        </Link>
      ) : (
        ''
      )
  },
  {
    title: 'Internal',
    width: 100,
    key: BoardDeviceAssociationSortBy.internalBoard,
    render: (bda: BoardDeviceAssociation) => (bda.internalBoard ? <Check /> : '')
  },
  {
    title: 'Last Indexed',
    width: 220,
    key: BoardDeviceAssociationSortBy.lastIndexed,
    render: (bda: BoardDeviceAssociation) => (
      <FlexHorizontal>
        <Box style={{ paddingTop: 7 }}>
          {bda.lastIndexed ? <FormattedDateTime format='MM/DD/YYYY hh:mm a' value={bda.lastIndexed} /> : ''}{' '}
        </Box>
        <Button
          onClick={() => {
            indexBoardDeviceAssociations(bda.id)
          }}>
          <Refresh />
        </Button>
      </FlexHorizontal>
    )
  }
]

const toKeyValue = (key: string) => ({ key, value: key })

const searchTypes = [
  {
    key: 'terms',
    title: 'All',
    type: 'text' as 'text'
  },
  {
    key: 'yoctoStatus',
    title: 'Yocto Status',
    type: 'select' as 'select',
    options: [
      'YoctoInstalled',
      'Rebooting',
      'EligibleForYoctoAsOf',
      'UpgraderInstallationBegan',
      'UpgraderInstallationCompleted',
      'Unknown'
    ].map(toKeyValue)
  },
  {
    key: 'deviceModelTitle',
    title: 'Device Model',
    type: 'select' as 'select',
    options: [
      'Flagship EVT',
      'Flagship DVT',
      'Flagship PVT',
      'Flagship White EVT',
      'Flagship White DVT',
      'Flagship White PVT'
    ].map(toKeyValue)
  },
  {
    key: 'boardDeleted',
    title: 'Board Deleted',
    type: 'boolean' as 'boolean'
  },
  {
    key: 'online',
    title: 'Online',
    type: 'boolean' as 'boolean'
  },
  {
    key: 'productPricingRecurringFrequency',
    title: 'Subscription Frequency',
    type: 'select' as 'select',
    options: ['Annually', 'EveryThreeYears'].map(toKeyValue)
  },
  {
    key: 'messagesAllTime',
    title: 'Messages Sent All Time',
    type: 'range' as 'range'
  },
  {
    key: 'messagesInPastDay',
    title: 'Messages In Past Day',
    type: 'range' as 'range'
  },
  {
    key: 'messagesInPastSevenDays',
    title: 'Messages In Past Seven Days',
    type: 'range' as 'range'
  },
  {
    key: 'messagesInPastThirtyDays',
    title: 'Messages In Past Thirty Days',
    type: 'range' as 'range'
  },
  {
    key: 'boardCreated',
    title: 'Board Created Date',
    type: 'dateRange' as 'dateRange'
  },
  {
    key: 'boardDeviceAssociationCreated',
    title: 'Board Paired Date',
    type: 'dateRange' as 'dateRange'
  },
  {
    key: 'deviceCreated',
    title: 'Device Created Date',
    type: 'dateRange' as 'dateRange'
  },
  {
    key: 'deviceLastSeen',
    title: 'Device Last Seen',
    type: 'dateRange' as 'dateRange'
  },
  {
    key: 'lastMessage',
    title: 'Last Message',
    type: 'dateRange' as 'dateRange'
  },
  {
    key: 'productApplicationCreated',
    title: 'Product Application Created',
    type: 'dateRange' as 'dateRange'
  },
  {
    key: 'firmwareVersion',
    title: 'Firmware Version',
    type: 'select' as 'select',
    options: ['Unknown', 'PVT', 'YOCTO1', 'YOCTO2', 'YOCTO3'].map(toKeyValue)
  },
  {
    key: 'osVersionName',
    title: 'OS Version Name',
    type: 'text' as 'text'
  },
  {
    key: 'mysteryBoards',
    title: 'Mystery Boards',
    type: 'button' as 'button'
  },
  {
    key: 'isPlus',
    title: 'Plus Boards',
    type: 'button' as 'button'
  }
].sort((a, b) => a.title.localeCompare(b.title))

const useHashParams = () => {
  const hash = window.location.hash
    .replace('?', '')
    .replace('#', '')
    .split('&')
    .reduce((prev, current) => {
      const [key, value] = current.split('=')
      return {
        ...prev,
        [key]: decodeURI(value)
      }
    }, {}) as any

  if (
    typeof hash.searchValue === 'string' &&
    (hash.searchValue.includes('min') ||
      hash.searchValue.includes('false') ||
      hash.searchValue.includes('true') ||
      hash.searchValue.includes('null'))
  ) {
    hash.searchValue = JSON.parse(hash.searchValue)
  }

  return hash
}

export const PairedBoards = () => {
  const hashParams = useHashParams()
  const [perPage] = useState(hashParams.perPage ? +hashParams.perPage : 50)
  const [sortBy, setSortBy] = useState<BoardDeviceAssociationSortBy>(
    hashParams.sortBy || BoardDeviceAssociationSortBy.boardDeviceAssociationCreated
  )
  const [sortOrder, setSortOrder] = useState<BoardDeviceAssociationSortOrder>(BoardDeviceAssociationSortOrder.desc)
  const [searchValue, setSearchValue] = useState<SearchValueType>(hashParams.searchValue || null)
  const [page, setPage] = useState(hashParams.page ? +hashParams.page : 0)
  const [searchType, setSearchType] = useState(hashParams?.searchType || 'terms')
  const [searchModel, setSearchModel] = useState(hashParams?.searchModel || 'boardDeviceAssociations')
  const variables =
    searchType === 'isPlus'
      ? {
          isPlus: true,
          sortBy,
          sortOrder
        }
      : searchType === 'mysteryBoards'
      ? {
          mysteryBoards: true,
          sortBy,
          sortOrder
        }
      : {
          ...(searchValue || searchValue === false
            ? {
                [searchType]: typeof searchValue === 'string' ? (searchValue || '').trim() : searchValue
              }
            : {}),
          sortBy,
          sortOrder
        }
  const { data, loading, error, refetch } = useBoardDeviceAssociations({
    input: {
      ...variables,
      perPage,
      page
    }
  })
  const [indexBoardDeviceAssociation] = useIndexBoardDeviceAssociations()
  const [reindexBdaByBoardId] = useMutation(REINDEX_MUTATION)

  const boardDeviceAssociations = data?.listBoardDeviceAssociations.boardDeviceAssociations || []
  const pagination = data?.listBoardDeviceAssociations?.pagination

  useEffect(() => {
    const searchValueText = typeof searchValue === 'string' ? searchValue : JSON.stringify(searchValue)
    window.location.hash = `?sortBy=${sortBy}&sortOrder=${sortOrder}&page=${page}&perPage=${perPage}&searchType=${searchType}&searchValue=${
      searchValueText || ''
    }&searchModel=${searchModel}`
  }, [sortBy, sortOrder, page, searchType, searchValue, perPage, searchModel])

  const indexBoardDeviceAssociations = useCallback(
    async (boardDeviceAssociationId: string) => {
      await indexBoardDeviceAssociation({
        variables: {
          boardDeviceAssociationId
        }
      }).catch(() => {})
      await refetch()
    },
    [indexBoardDeviceAssociation, refetch]
  )

  return (
    <>
      <SuperGrid
        title='Paired Boards'
        searchType={searchType}
        setSort={({ by, order }) => {
          setSortBy(by)
          setSortOrder(order)
        }}
        columns={columns({ indexBoardDeviceAssociations })}
        rows={boardDeviceAssociations}
        sortBy={sortBy}
        sortOrder={sortOrder}
        pagination={pagination}
        setPage={setPage}
        error={!!error}
        loading={loading}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        disableWarnings={searchModel !== 'boardDeviceAssociations'}
        renderSearch={
          <AdvancedSearch
            searchTypes={searchTypes}
            value={searchValue}
            setValue={value => {
              setSearchModel('boardDeviceAssociations')
              setPage(0)
              setSearchValue(value)
            }}
            searchType={searchType}
            setSearchType={setSearchType}
          />
        }
        centerButtons={
          <Box display='flex'>
            <CsvImport />
            <Box width={7} />
            <CsvExport
              query={BOARD_DEVICE_ASSOCIATIONS}
              variables={{
                input: variables
              }}
              getPagination={({ data }) => ({
                ...data.listBoardDeviceAssociations.pagination,
                page: data.listBoardDeviceAssociations.pagination.page + 1
              })}
              formatData={({ data }) => {
                return data?.listBoardDeviceAssociations.boardDeviceAssociations.map((bda: BoardDeviceAssociation) => ({
                  ...bda,
                  boardDeviceAssociationCreated: bda.boardDeviceAssociationCreated
                    ? moment(bda.boardDeviceAssociationCreated).format(DATE_TIME_FORMAT)
                    : null,
                  deviceCreated: bda.deviceCreated ? moment(bda.deviceCreated).format(DATE_TIME_FORMAT) : null,
                  boardCreated: bda.boardCreated ? moment(bda.boardCreated).format(DATE_TIME_FORMAT) : null,
                  productApplicationCreated: bda.productApplicationCreated
                    ? moment(bda.productApplicationCreated).format(DATE_TIME_FORMAT)
                    : null,
                  lastMessage: bda.lastMessage ? moment(bda.lastMessage).format(DATE_TIME_FORMAT) : null,
                  deviceLastSeen: bda.deviceLastSeen ? moment(bda.deviceLastSeen).format(DATE_TIME_FORMAT) : null,
                  lastIndexed: bda.lastIndexed ? moment(bda.lastIndexed).format(DATE_TIME_FORMAT) : null,
                  memberEmailAddresses: bda.memberEmailAddresses.join(' '),
                  members: `${bda.memberIds.length}` || '0',
                  osVersionName: bda?.osVersionName
                }))
              }}
            />
          </Box>
        }
      />
      {!loading &&
      !boardDeviceAssociations.length &&
      searchModel === 'boardDeviceAssociations' &&
      typeof searchValue === 'string' &&
      isUUID(searchValue) ? (
        <>
          <Box style={{
            marginBottom: 10 
          }}>
            <Button
              color='primary'
              variant='outlined'
              onClick={async () => {
                await reindexBdaByBoardId({
                  variables: { boardId: searchValue },
                })
                await refetch()
              }}>
              Refresh index for Board Id: {searchValue}
            </Button>
          </Box>
          <Box>
            <Button
              color='primary'
              variant='outlined'
              onClick={() => {
                setSearchModel('deviceId')
              }}>
              Search Unpaired Devices
            </Button>
          </Box>
        </>
      ) : null}

      {typeof searchValue === 'string' && searchModel === 'deviceId' ? <SearchDeviceId id={searchValue} /> : null}
      {!data?.listBoardDeviceAssociations?.boardDeviceAssociations?.length &&
      typeof searchValue === 'string' &&
      searchValue.includes('Vestaboard-') ? (
        <SearchSSIDComponent ssid={searchValue} />
      ) : null}
    </>
  )
}
